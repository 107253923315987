<template>
  <div class="p-d-flex job-form">
    <div class="p-col-6 p-grid p-fluid padding-top">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <InputText
            id="ipttxt-empresa"
            type="text"
            v-model="jobActiveForm.empresa"
          />
          <label for="ipttxt-empresa">Empresa</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <InputText
            id="ipttxt-puesto"
            type="text"
            v-model="jobActiveForm.puesto"
          />
          <label for="ipttxt-puesto">Puesto</label>
        </span>
      </div>
      <div class="p-field p-col-12 ">
        <span class="p-float-label">
          <InputText
            id="ipttxt-estado"
            type="text"
            v-model="jobActiveForm.estado"
          />
          <label for="ipttxt-estado">Estado</label>
        </span>
      </div>
      <div class="p-field p-col-12 ">
        <span class="p-float-label">
          <InputText
            id="ipttxt-zona"
            type="text"
            v-model="jobActiveForm.zona"
          />
          <label for="ipttxt-zona">Zona</label>
        </span>
      </div>

      <div class="p-field p-col-12 ">
        <span class="p-float-label">
          <InputText
            id="ipttxt-horario"
            type="text"
            v-model="jobActiveForm.horario"
          />
          <label for="ipttxt-horario">Horario</label>
        </span>
      </div>

      <div class="p-field p-col-12">
        <span class="p-float-label">
          <Dropdown
            id="dropdown-imagen"
            v-model="imagen"
            :options="images"
            optionLabel="title"
          >
            <template #value="slotProps">
              <div class="image-item image-item-value" v-if="slotProps.value">
                <img :src="slotProps.value.thumbnailImageSrc" />
                <!-- <div>{{ slotProps.value.title }}</div> -->
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="image-item">
                <img :src="slotProps.option.thumbnailImageSrc" />
                <!-- <div>{{ slotProps.option.title }}</div> -->
              </div>
            </template>
          </Dropdown>
          <label for="dropdown-imagen">Imagen</label>
        </span>
      </div>

      <!-- <div class="p-field p-col-12">
        <Skeleton height="10rem" class="p-mb-2"></Skeleton>
      </div> -->
    </div>
    <Divider layout="vertical" />
    <div class="p-col-6 p-grid p-fluid padding-top">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <Textarea id="txta-requisitos" v-model="requisitos" rows="3" />
          <label for="txta-requisitos">Requisitos</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <Textarea id="txta-conocimientos" v-model="conocimientos" rows="3" />
          <label for="txta-conocimientos">Conocimientos</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <Textarea id="txta-funciones" v-model="funciones" rows="3" />
          <label for="txta-funciones">Funciones</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <Textarea id="txta-prestaciones" v-model="prestaciones" rows="3" />
          <label for="txta-prestaciones">Prestaciones</label>
        </span>
      </div>
      <div class="p-col-12">
        <span class="p-buttonset">
          <Button label="Guardar" @click="saveJob" />
          <Button
            label="Cancelar"
            @click="hideJobModal"
            class="p-button-danger"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      jobActiveForm: {
        id: null,
        empresa: null,
        puesto: null,
        estado: null,
        zona: null,
        horario: null,
        imagen: null,
        requisitos: [],
        conocimientos: [],
        funciones: [],
        prestaciones: [],
      },
    };
  },
  computed: {
    ...mapState(["jobActive", "images"]),
    imagen: {
      set(value) {
        this.jobActiveForm.imagen = value;
      },
      get() {
        return this.jobActiveForm.imagen;
      },
    },
    requisitos: {
      set(value) {
        this.jobActiveForm.requisitos = this.stringToRecord(value);
      },
      get() {
        return this.recordsToString(this.jobActiveForm.requisitos);
      },
    },
    conocimientos: {
      set(value) {
        this.jobActiveForm.conocimientos = this.stringToRecord(value);
      },
      get() {
        return this.recordsToString(this.jobActiveForm.conocimientos);
      },
    },
    funciones: {
      set(value) {
        this.jobActiveForm.funciones = this.stringToRecord(value);
      },
      get() {
        return this.recordsToString(this.jobActiveForm.funciones);
      },
    },
    prestaciones: {
      set(value) {
        this.jobActiveForm.prestaciones = this.stringToRecord(value);
      },
      get() {
        return this.recordsToString(this.jobActiveForm.prestaciones);
      },
    },
  },
  created() {
    if (this.jobActive !== null) {
      this.jobActiveForm = this.jobActive;
    }

    this.getImagesFromStore({ app: this });
  },
  methods: {
    ...mapActions(["getImagesFromStore", "hideJobModal", "storeJob"]),
    recordsToString(records) {
      let str = "";
      if (this.isIterable(records)) {
        records.forEach(function(r) {
          str += str.trim() === "" ? `${r}` : `, ${r}`;
        });
      }
      return str;
    },
    stringToRecord(string) {
      let a = string.split(",");
      for (let i in a) {
        a[i] = a[i].trim();
      }
      return a;
    },
    saveJob() {
      this.storeJob({ payload: this.jobActiveForm, app: this });
    },
    isIterable(obj) {
      // checks for null and undefined
      if (obj == null) {
        return false;
      }
      return typeof obj[Symbol.iterator] === "function";
    },
  },
};
</script>

<style scoped>
.padding-top {
  padding-top: 20px;
}

.image-item img {
  height: 183px;
  widows: 350px;
}
</style>
