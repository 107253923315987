<template>
  <div>
    <ProgressBar :value="progressListJobs" :showValue="false" v-if="load" />
    <BlockUI :blocked="load">
      <DataTable
        ref="dt"
        :value="jobs"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
      >
        <template #header>
          <div
            class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
          >
            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">Registro de Vacantes</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
        <Column field="estado" header="Estado" :sortable="true"></Column>
        <Column field="zona" header="Zona" :sortable="true"></Column>
        <Column field="empresa" header="Empresa" :sortable="true"></Column>
        <Column field="puesto" header="Puesto" :sortable="true"></Column>
        <Column field="imagen" header="Imagen">
          <template #body="slotProps">
            <Image :src="slotProps.data.imagen" class="job-image" />
          </template>
        </Column>
        <Column :exportable="false">
          <template #body="slotProps">
            <Button
              icon="pi pi-link"
              class="p-button-rounded p-button-info p-mr-2"
              @click="copyLink(slotProps.data)"
            />
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              @click="edit(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDelete(slotProps.data, slotProps.index)"
            />
          </template>
        </Column>
      </DataTable>
    </BlockUI>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { FilterMatchMode } from "primevue/api";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
const Image = defineAsyncComponent(() => import("../Image.vue"));
export default {
  data() {
    return {
      load: false,
      filters: {},
    };
  },
  computed: {
    ...mapState(["jobs"]),
    ...mapGetters(["progressListJobs"]),
  },
  watch: {
    progressListJobs: function(nVal) {
      if (Math.ceil(nVal) >= 100) {
        this.load = false;
      }
    },
  },
  created() {
    this.load = true;
    this.getJobsFromStore({ app: this });
    this.initFilters();
  },
  methods: {
    ...mapActions(["getJobsFromStore", "selectedJob", "deleteJob"]),
    confirmDelete(job, index) {
      this.$confirm.require({
        message: `¿Desea eliminar la vacante de ${job.puesto} en ${job.estado}, ${job.zona}?`,
        header: "Confirmación de eliminación",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Eliminar",
        accept: () => {
          this.deleteJob({ uuid: job.id, index, app: this });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Eliminación cancelada",
            detail: "Vacante aún disponible",
            life: 3000,
          });
        },
      });
    },
    edit(job) {
      this.selectedJob({ job });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    copyLink(job) {
      var getUrl = window.location;
      var baseUrl = getUrl.protocol + "//" + getUrl.host;
      const el = document.createElement("textarea");
      el.value = `${baseUrl}/#/app/jobs/${job.id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.add({
        severity: "info",
        summary: "Link copiado",
        detail: "El enlace de la vacante esta en su portapapeles",
        life: 3000,
      });
    },
  },
  components: {
    Image,
  },
};
</script>

<style>
.job-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

span.job-image img {
  width: 50px;
}
</style>
