<template>
  <div class="p-grid">
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="Nueva"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="openNew"
        />
      </template>
    </Toolbar>
    <div class="p-col-12">
      <DatatableJobs />
    </div>
    <Toast />
    <Dialog
      :header="`${jobModalTitlePrefix} vacante`"
      :modal="true"
      v-model:visible="display"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '70vw' }"
    >
      <FormJob />
    </Dialog>
  </div>
</template>

<script>
import DatatableJobs from "../../components/admin/DatatableJobs.vue";
import FormJob from "../../components/admin/FormJob.vue";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapState(["jobModalDisplay"]),
    ...mapGetters(["jobModalTitlePrefix"]),
    display: {
      get() {
        return this.jobModalDisplay;
      },
      set() {
        this.hideJobModal();
      },
    },
  },
  methods: {
    ...mapActions(["selectedJob", "hideJobModal"]),
    openNew() {
      this.selectedJob({ job: null });
    },
  },
  components: {
    DatatableJobs,
    FormJob,
  },
};
</script>
